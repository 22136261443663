import store from '../store';

import { validateUser } from '@/utils/user';

export const validateRouteWithAuthData = async (to, from, next) => {
  if (to.name === 'login') {
    store.commit('app/HIDE_FULL_PAGE_LOADER');
    store.commit('web3auth/PURGE_AUTH');

    return next();
  }

  if (!store.getters['web3auth/userdata']._id || from.name === 'onboarding') {
    await store.dispatch('web3auth/CONNECT', { auto: true });
  }

  store.commit('app/HIDE_FULL_PAGE_LOADER');

  const userdata = store.getters['web3auth/userdata'];

  if ((to.meta.authRequired && !userdata._id) || (to.meta.isAdmin && !userdata.isAdmin)) {
    return next({ name: 'home' });
  }

  if (to.meta.onbordingRequired) {
    const valid = validateUser(userdata);

    if (!valid) {
      return next({ name: `onboarding` });
    }
  }

  if (to.name == 'onboarding') {
    const valid = validateUser(userdata);

    if (valid) {
      return next({ name: 'home' });
    }
  }

  next()
};
