<template>
  <v-row class="mb-2 mt-0 my-0">
    <v-col cols="12" sm="6">
      <v-select
        label="Offer"
        outlined
        v-model="asset.integration.affiliate.offer"
        :items="offersList"
        item-text="name"
        item-value="_id"
        hint="Select Promote2Earn Offer"
        persistent-hint
        clearable
        dense
        hide-details="auto"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    offersList: Array,
  },
}
</script>
