<template>
  <v-row class="mx-0 my-0">
    <v-col cols="12" sm="6">
      <v-text-field
        label="Name*"
        required
        outlined
        v-model="asset.name"
        dense
        hint="Name of asset"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        label="Preview"
        outlined
        v-model="asset.preview"
        dense
        hint="Enter image link of your collection"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        label="Key"
        outlined
        v-model="asset.key"
        dense
        hint="Short handle for your asset"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        label="Discord"
        outlined
        v-model="asset.discord"
        dense
        hint="Discord invite link (optional)"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        label="Website"
        outlined
        v-model="asset.website"
        dense
        hint="Website Link (optional)"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        label="Twitter"
        outlined
        v-model="asset.twitter"
        dense
        hint="Enter the link to the twitter account"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12" sm="6" 
      v-if="asset.category === 'nft' && asset.type === 'custom'"
    >
      <v-text-field
        label="Price"
        outlined
        v-model="asset.payment.price"
        dense
        type="number"
        step=".1"
        hint="Selling price. It's just an information field"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <vue-editor v-model="asset.description"></vue-editor>
    </v-col>
  </v-row>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'asset.name': function (newValue, oldValue) {
      const oldKey = this.getKeyFromString(oldValue)

      if (!this.asset.key || this.asset.key === oldKey) {
        this.asset.key = this.getKeyFromString(newValue)
      }
    },
  },
  methods: {
    getKeyFromString(str) {
      return (str || '')
        .toLowerCase()
        .split(' ')
        .filter(it => !!it)
        .join('_')
    },
  },
}
</script>
