import {
  mdiInformation, mdiHomeOutline, mdiWalletOutline, mdiSecurity, mdiFormatListText
} from '@mdi/js';

/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Pages' },

  { title: 'Welcome', icon: mdiHomeOutline, to: 'home' },
  { title: 'Projects', icon: mdiFormatListText, to: 'projects' },
  { title: 'Offers', icon: mdiFormatListText, to: 'offers' },
  { title: 'Profit', icon: mdiWalletOutline, to: 'profit' },
  //{ title: 'About Us', icon: mdiInformation, to: 'aboutUs' },
  //{ title: 'Terms & Conditions', icon: mdiSecurity, to: 'terms' },
  //{ title: 'Privacy Policy', icon: mdiSecurity, to: 'privacy' }
];
