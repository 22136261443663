<template>
  <v-app
    :class="[{'nav-drawer-mini': menuIsVerticalNavMini}, {'content-full': appContentWidth === 'full'}]"
    class="content-layout vertical-nav"
  >
    <!-- <v-navigation-drawer
      v-if="!menuIsMenuHidden"
      ref="layoutDrawer"
      v-model="isVerticalNavMenuActive"
      app
      :right="$vuetify.rtl"
      mini-variant-width="68"
      :expand-on-hover="menuIsVerticalNavMini"
      width="260"
      class="app-navigation-menu"
    >
      <vertical-nav-menu
        :nav-menu-items="menuItems"
        @close-nav-menu="isVerticalNavMenuActive = false"
      />
    </v-navigation-drawer> -->

    <!-- TODO: All elevations are to bottom and footer requires shadow on top -->
    <v-app-bar
      v-if="appBarType !== 'hidden'"
      ref="refAppBar"
      app
      :absolute="appBarType === 'static'"
      flat
      :elevate-on-scroll="appBarType !== 'static'"
      :elevation="appBarType !== 'static' ? $vuetify.theme.isDark ? 4: 3 : 0"
      class="mx-auto app-bar-static"
      :class="[{ 'px-5 app-bar-shinked': appBarType === 'fixed' && scrollY }, { 'bg-blur': appBarIsBlurred && appBarType === 'fixed' && scrollY }]"
    >
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <svg-changer class="me-3" width="150px" :fill="isDark ? { '#26222C': '#fff' } : {}">
            <app-logo></app-logo>
          </svg-changer>
          <!-- <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon> -->
          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search> -->
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!-- <app-bar-i18n></app-bar-i18n> -->
          <!-- <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher> -->
          <app-bar-user-menu class="ml-4"></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <app-content-container>
        <slot></slot>
      </app-content-container>
    </v-main>
    <v-overlay
      :value="$store.state.app.shallContentShowOverlay"
      z-index="4"
      absolute
      class="content-overlay"
    ></v-overlay>

    <v-footer
      v-if="footerType !== 'hidden'"
      app
      inset
      :absolute="footerType === 'static'"
      padless
      :class="{'mx-auto': appContentWidth !== 'full'}"
      :color="footerType === 'static' ? 'transparent' : null"
    >
      <div
        :class="{'px-5': footerType === 'fixed'}"
        class="py-4 w-full"
      >
        <div class="d-flex justify-space-between">
          <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
            href="https://promote2earn.com"
            class="text-decoration-none"
          >LuckyDegens</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
          <div class="align-center d-none d-md-flex">
            <span></span>

          </div>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { ref, watch, computed } from '@vue/composition-api'
import AppContentContainer from '@/layouts/components/app-content-container/AppContentContainer.vue'
import { getVuetify, useRouter } from '@/utils'
import useLayoutConfig from '@/composable/useLayoutConfig'
import VerticalNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import { useWindowScroll } from '@vueuse/core'

import navMenuItems from '@/navigation/vertical'

import AppBarSearch from '@/layouts/components/app-bar/AppBarSearch.vue'
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/app-bar/AppBarUserMenu.vue'
import AppBarContractLink from '@/layouts/components/app-bar/AppBarContractLink.vue'

import AppLogo from '@/assets/images/svg/logo.svg'

import appBarSearchData from '@/assets/app-bar-search-data'

import { mdiMenu, mdiHeartOutline } from '@mdi/js'

export default {
  components: {
    AppLogo,
    AppContentContainer,
    VerticalNavMenu,

    AppBarSearch,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarContractLink,
  },
  setup() {
    /* eslint-disable object-curly-newline, operator-linebreak */
    const { menuIsVerticalNavMini, menuIsMenuHidden, appBarType, appBarIsBlurred, footerType, appContentWidth, isDark } =
      useLayoutConfig()
    const $vuetify = getVuetify()
    const { route } = useRouter()

    const isVerticalNavMenuActive = ref(true)
    const toggleVerticalNavMenuActive = () => {
      isVerticalNavMenuActive.value = !isVerticalNavMenuActive.value
    }

    const { y: scrollY } = useWindowScroll()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })

    // TODO: Check do we need below watch
    watch(
      () => $vuetify?.breakpoint.mdAndDown,
      value => {
        isVerticalNavMenuActive.value = !value
      },
      {
        immediate: true,
      },
    )
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      isVerticalNavMenuActive,
      toggleVerticalNavMenuActive,
      menuIsVerticalNavMini,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,
      isDark,
      scrollY,

      handleShallShowFullSearchUpdate,

      appBarSearchQuery,
      shallShowFullSearch,
      searchFilterFunc,
      appBarSearchData,

      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
  computed: {
    ...mapGetters('web3auth', ['userdata']),
    menuItems() {
      return navMenuItems.filter((item) => {
        return !((item.authRequired && !this.userdata._id) || (item.isAdmin && !this.userdata.isAdmin));
      });
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/layouts/styles/_variables';
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.app-content-container {
  padding: $content-padding-vertical-navigation-menu;
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

// Vuetify Fix
// https://github.com/vuetifyjs/vuetify/issues/13327
$nav-drawer-mini-width: 68px;

.v-application {
  .v-main,
  .v-footer {
    transition-duration: 0.3s;
  }

  .v-footer {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @include theme(v-footer) using ($material) {
    // Elevation 3 with -y
    &.v-footer--fixed {
      box-shadow: 0 -4px 8px -4px rgba(map-deep-get($material, 'shadow-color'), 0.42) !important;
    }
  }

  &.nav-drawer-mini {
    .v-main {
      // We haven't used `ltr` & `rtl` mixin because those doesn't work with top-level selectors: https://github.com/vuetifyjs/vuetify/issues/13987
      @at-root {
        .nav-drawer-mini {
          &.v-application--is-ltr {
            .v-main {
              padding-left: $nav-drawer-mini-width !important;
            }
          }
          &.v-application--is-rtl {
            .v-main {
              padding-right: $nav-drawer-mini-width !important;
            }
          }
        }
      }
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      .app-navigation-menu ~ .v-footer,
      .app-navigation-menu + .v-app-bar {
        left: $nav-drawer-mini-width !important;
      }
    }
  }

  .v-app-bar,
  .v-footer {
    max-width: calc(1440px - (1.5rem * 2));
    @media screen and (max-width: 1456px) {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }

    @at-root .v-application {
      &.content-full {
        .v-footer,
        .v-app-bar {
          max-width: unset;
          margin-left: $content-padding-vertical-navigation-menu !important;
          margin-right: $content-padding-vertical-navigation-menu !important;
        }
      }

      &:not(.nav-drawer-mini) {
        @media screen and (max-width: 1711px) {
          .app-navigation-menu ~ .v-footer,
          .app-navigation-menu + .v-app-bar {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
          }
        }
      }
      &.nav-drawer-mini {
        @media screen and (max-width: 1523px) {
          .v-footer,
          .v-app-bar {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
          }
        }
      }
    }
  }

  .v-app-bar {
    border-radius: 0 0 10px 10px !important;
    z-index: 5;

    &.v-toolbar:not(.app-bar-shinked) {
      background-color: transparent;
    }

    &.app-bar-static {
      will-change: padding, background-color;
      transition: padding 0.2s ease, background-color 0.18s ease, left 0.3s ease;

      &.v-toolbar.v-sheet:not(.v-app-bar--is-scrolled) {
        box-shadow: none !important;
      }

      ::v-deep > .v-toolbar__content {
        padding: 0;
      }
    }
  }
}

@include theme(v-app-bar) using ($material) {
  &.v-toolbar.app-bar-shinked {
    background-color: map-deep-get($material, 'cards');
  }
}

.v-application.content-layout {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .v-main,
    .v-footer,
    .v-app-bar {
      max-width: unset;
      left: 0 !important;
      @include ltr() {
        padding-left: 0 !important;
      }
      @include rtl() {
        padding-right: 0 !important;
      }
    }
  }
}
</style>
