<template>
  <v-row class="mx-0 my-0">
    <v-col cols="12" sm="6">
      <v-select
        :items="blockchainList"
        label="Blockchain*"
        required
        outlined
        v-model="asset.blockchain"
        :disabled="!canEditWeb3Fields"
        @change="asset.payment.token = 'USDT'"
        hint="Specify the blockchain"
        persistent-hint
        dense
        hide-details="auto"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        label="Contract Address*"
        outlined
        :disabled="!canEditWeb3Fields"
        v-model="asset.contract.address"
        hint="Specify the address of the contract"
        persistent-hint
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    canEditWeb3Fields: Boolean,
  },
  data: () => ({
    blockchainList: ['ETH', 'POLYGON', 'BSC', 'BSC_TESTNET'],
  }),
}
</script>
