import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import Web3 from 'web3';
import Web3Modal from 'web3modal';

import router from '@/router';

import { getAxios } from '@/utils';
import { AUTH_TOKEN } from '@/utils/constants';

export default {
  namespaced: true,
  state: {
    user: {},
    token: null,
    connecting: false,
    isMetaMask: false,
    account: null,
    balance: 0,
    chainId: null,
    chainName: null,
    web3: null,
    provider: null,
    w3mObject: null
  },
  getters: {
    userdata(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.token && state.user && state.user._id;
    }
  },
  mutations: {
    SET_WEB3_PROVIDER_DATA(state, { web3, provider, w3mObject }) {
      state.web3 = web3;
      state.provider = provider;
      state.w3mObject = w3mObject;
    },
  
    SET_ACTIVE_ACCOUNT(state, account) {
      state.account = account;
    },
  
    SET_WEB3_CONNECTING(state, connecting) {
      state.connecting = connecting;
    },
  
    SET_CHAIN_DATA(state, chainId) {
      state.chainId = chainId;
    },
  
    SET_IS_METAMASK(state, isMetaMask) {
      state.isMetaMask = isMetaMask;
    },
  
    SET_USER(state, user) {
      state.user = user;
      state.token = state.user.token;
  
      localStorage.setItem(AUTH_TOKEN, state.user.token);
    },
  
    UPDATE_USER(state, data) {
      state.user = {
        ...state.user, ...data,
        project: {
          ...state.user.project,
          ...(data.project || {})
        }
      };
    },
  
    PURGE_AUTH(state) {
      state.user = {};
      state.token = null;
  
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
  
      state.connecting = false;
  
      state.isMetaMask = false;
      state.account = null;
      state.balance = 0;
      state.chainId = null;
      state.chainName = null;
      state.web3 = null;
      state.provider = null;
      state.w3mObject = null;
    }
  },
  actions: {
    async CONNECT({ state, commit, dispatch }, { auto, role }) {
      commit('SET_WEB3_CONNECTING', true);
  
      const providerOptions = {
        // MetaMask is enabled by default,
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "ca654494c372484ab5be5040d038c787", // required,
            rpc: {
              1: "https://mainnet.infura.io/v3/ca654494c372484ab5be5040d038c787",
              56: "https://bsc-dataseed.binance.org",
              97: "https://data-seed-prebsc-2-s1.binance.org:8545",
              137: "https://polygon-rpc.com"
            }
          }
        }
      };
      
      const w3mObject = new Web3Modal({
        cacheProvider: true,
        network: 'mainnet',
        providerOptions
      });
  
      if (auto && !w3mObject.cachedProvider) {
        return commit('PURGE_AUTH');
      };
  
      if (auto && w3mObject.cachedProvider === 'injected') {
        const existExtension = window.ethereum;
  
        if (!existExtension) {
          return commit('PURGE_AUTH');
        }
    
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
    
        const unlocked = await signer
          .getAddress()
          .then(() => true)
          .catch(() => false);
        
        if(!unlocked) {
          commit('PURGE_AUTH');
          return;
        }
      }
  
      const provider = await w3mObject.connect().catch(() => false);
    
      if (!provider) {
        return commit('PURGE_AUTH');
      }
      
      // Subscribe to accounts change
      provider.on("accountsChanged", async (accounts) => {
        if (state.user._id) {
          commit('PURGE_AUTH');
          router.push({ name: 'home'});
        }
      });
  
      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        commit('SET_CHAIN_DATA', chainId);
      });
  
      // Subscribe to provider connection
      provider.on("connect", (info) => {
        console.log('Connected ->', info);
      });
  
      // Subscribe to provider disconnection
      provider.on("disconnect", (errorCode) => {
        if (errorCode === 1000) {
          commit('PURGE_AUTH');
        }
      });
  
      const web3 = new Web3(provider);
  
      commit('SET_WEB3_PROVIDER_DATA', {
        web3, provider, w3mObject
      });
      commit('SET_IS_METAMASK', !!provider.isMetaMask);
  
      const accounts = await web3.eth.getAccounts();
      const chainId = await web3.eth.getChainId();
  
      commit('SET_ACTIVE_ACCOUNT', accounts[0]);
      commit('SET_CHAIN_DATA', chainId);
  
      await dispatch('LOGIN', { role });
  
      commit('SET_WEB3_CONNECTING', false);
    },
  
    async LOGIN({ rootState, state, commit }, { role }) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      
      const existWeb3Config = Object.keys(rootState.app.web3.networks).length;
  
      await getAxios().post("auth/connect", {
        ...params,
        wallet: state.account,
        config: !existWeb3Config,
        role
      })
        .then(({ data }) => {
          const { user, web3config } = data;
          
          if (web3config) {
            commit('app/SET_WEB3_CONFIG', web3config, { root: true });
          }
  
          commit('SET_USER', user);
        })
        .catch((err) => {
          console.log(`[AUTH ERROR]: ${err}`)
        });
    },
  
    async VERIFY({ state, commit }) {
      if (!state.token || !state.account) return;
  
      await getAxios().get("api/auth/verify")
        .then(({ data }) => {
          const { user } = data;
      
          commit('SET_USER', user);
        })
        .catch((err) => {
          console.log(`[AUTH ERROR]: ${err}`)
        });
    },
  
    async SWITCH_NETWORK({ state }, chainId) {
      if (state.chainId === parseInt(chainId)) return true;
  
      if (!state.isMetaMask) return false;
    
      return state.web3.currentProvider.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        }).then(() => true)
          .catch(() => false);
    }
  }
};
