<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Edit Project [{{projectData.name}}]</span>
    </v-card-title>

    <v-card-text>
      <v-row class="mb-1">
        <v-col cols="12" sm="6">
          <v-text-field
            label="Name*"
            required
            outlined
            dense
            v-model="project.name"
            persistent-hint
            hint="Please enter your full company name!"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Website*"
            required
            outlined
            dense
            v-model="project.website"
            hide-details="auto"
            persistent-hint
            hint="Please enter your company website address"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            label="Type*"
            :items="types"
            v-model="project.type"
            required
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Logo URI"
            required
            outlined
            dense
            v-model="project.logo"
            hide-details="auto"
            persistent-hint
            hint="We will display your logo in the marketplace. If you do not specify a logo, we will use our"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="pt-0" v-if="userdata.isAdmin">
          <v-switch
            class="mt-0 pt-0"
            label="Verified"
            outlined
            dense
            v-model="project.verified"
            hide-details="auto"
            persistent-hint
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-textarea
            label="Company Description"
            v-model="project.description"
            required
            outlined
            dense
            hide-details="auto"
          ></v-textarea>
        </v-col>
      </v-row>

      <small>*indicates required field</small>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error darken-1" text outlined @click="$emit('close-modal')" :disabled="saving">Close</v-btn>
      <v-btn
        color="primary darken-1"
        outlined text
        :disabled="!canSave"
        @click="saveProject()"
        :loading="saving"
      > Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    saving: false,
    statuses: ['active', 'blocked'],
    types: ['NFT', 'DEFI', 'P2E', 'Metaverse', 'Other'],
    status: null,
    project: {},
  }),
  created() {
    this.project = { ...this.projectData }
  },
  computed: {
    ...mapGetters('web3auth', ['userdata']),
    canSave() {
      return this.project.name && this.project.website && this.project.type
    },
  },
  methods: {
    saveProject() {
      if (this.saving) return

      this.saving = true

      this.$http
        .put(`project/${this.project._id}`, this.project)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Project successfully saved!`,
              },
              { root: true },
            )
          }

          this.$emit('update')
          this.$emit('close-modal')
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.saving = false
        })
    },
  },
}
</script>
