export default {
  namespaced: true,
  state: {
    list: [],
  },
  actions: {
    GENERATE_NOTIFICATION(context, options) {
      const notification = {
        id: Math.random().toString(16).slice(2),
        message: '',
        duration: 10,
        type: 'success',
        ...options
      };
  
      if (notification.type === 'error' && (!notification.message || notification.message.includes('undefined'))) {
        notification.message = 'UNKNOWN_ERROR';
      }
  
      context.commit('ADD_NOTIFICAION', notification);
  
      if (notification.duration) {
        setTimeout(() => {
          context.commit('REMOVE_NOTIFICAION', notification.id);
        }, notification.duration * 1000);
      }
    }
  },
  mutations: {
    ADD_NOTIFICAION(state, notification) {
      if (state.list.length > 1) {
        state.list.shift();
      }
    
      state.list.push(notification);
    },
    REMOVE_NOTIFICAION(state, notificationId) {
      state.list = state.list.filter((it) => it.id !== notificationId);
    }
  }
};
