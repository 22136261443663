<template>
  <v-card :loading="loading">
    <v-card-title>
      <span class="text-h5">Add Budget [Offer - {{offer.name}}]</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-alert
        v-if="alertOptions"
        border="left"
        :type="alertOptions.type"
        outlined
        class="mb-4"
      >
        {{alertOptions.message}}
      </v-alert>

      <div class="subtitle-2 mb-2">

        You selected <span class="font-weight-bold">{{offer.token}}</span> on <span class="font-weight-bold">{{offer.blockchain}}</span> blockchain to pay commission.
      </div>

      <h4>Current Budget: {{currentBudget}} {{offer.token}}</h4>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Amount*"
            required
            outlined
            dense
            type="number"
            step=".1"
            v-model="amount"
            hint="Enter the amount by which you want to top up the offer budget"
            persistent-hint
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error darken-1" text outlined @click="closeModal()" :disabled="loading">Close</v-btn>
      <v-btn
        color="primary darken-1"
        outlined text
        :disabled="!canAddBudget"
        :loading="loading"
        @click="addBudget"
      >Add Budget</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getContract, getEthersFromWei, getGasPriceForNetwork } from '@/utils/web3'

export default {
  props: {
    offerData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    verified: false,
    onchain: false,
    offer: {},
    web3Offer: {},
    amount: 0,
  }),
  created() {
    if (this.offerData) {
      this.offer = {
        ...this.offer,
        ...this.offerData,
      }
    }

    this.getOfferData()
  },
  computed: {
    ...mapGetters('web3auth', ['userdata']),
    ...mapState({
      web3: state => state.web3auth.web3,
      web3config: state => state.app.web3,
    }),
    canAddBudget() {
      if (this.onchain && Number(this.web3Offer.closedAfter)) return false

      return this.verified && this.amount > 0
    },
    currentBudget() {
      return getEthersFromWei(this.offer.blockchain, this.offer.token, (this.web3Offer.budget || 0).toString())
    },
    alertOptions() {
      // if (this.loading) {
      //   return { type: 'info', message: 'Wait please! An operation is in progress on the blockchain network!' };
      // }

      if (!this.loading && !this.verified) {
        return {
          type: 'error',
          message:
            'An error has occurred with the blockchain network. We cannot get data about the current state of the offer!',
        }
      }

      if (this.verified && !this.onchain) {
        return {
          type: 'info',
          message: `
          Your offer is not published yet. Add Budget to publish it to the blockchain`,
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('update')
      this.$emit('close-modal')
    },
    async getOfferData() {
      const { contract } = await getContract(this.offer.blockchain, this.offer.token, 'Affiliate')

      if (!contract) return (this.loading = false)

      this.web3Offer =
        (await contract.methods
          .offers(this.offer._id)
          .call()
          .catch(() => null)) || {}

      if (!Object.keys(this.web3Offer).length) return (this.loading = false)

      this.verified = true

      if (this.web3Offer.owner !== '0x0000000000000000000000000000000000000000') {
        this.onchain = true

        await this.$store.dispatch('offer/SAVE_OFFER_CACHE', {
          offerId: this.offer._id,
          web3data: this.web3Offer,
        })
      }

      this.loading = false
    },

    showActionErrorMessage(message = null) {
      this.$store.dispatch(
        'notification/GENERATE_NOTIFICATION',
        {
          type: 'error',
          message: 'Unable to perform this operation! ' + message,
        },
        { root: true },
      )

      this.loading = false
    },

    async addBudget() {
      if (this.loading || this.amount <= 0 || !this.verified) return

      this.loading = true

      const { networks, abi } = this.web3config
      const provider = networks[this.offer.blockchain] && networks[this.offer.blockchain].provider

      if (!provider) {
        return this.showActionErrorMessage('[Add Budget]')
      }

      const validNetwork = await this.$store.dispatch(
        'web3auth/SWITCH_NETWORK',
        networks[this.offer.blockchain].chainId,
      )

      if (!validNetwork) {
        return (this.loading = false)
      }

      const contractAbi = abi['Affiliate']
      const contractAddress = networks[this.offer.blockchain].contracts['Affiliate']

      if (!contractAbi || !contractAddress) {
        return this.showActionErrorMessage('[Add Budget]')
      }

      const contract = new this.web3.eth.Contract(contractAbi, contractAddress)

      const token = networks[this.offer.blockchain].coins[this.offer.token]
      const tokenAbi = abi.ERC20

      if (!token || !token.address || !tokenAbi) {
        return this.showActionErrorMessage('[Add Budget]')
      }

      const amount = BigInt(this.amount * 10 ** (token.decimals || 18)).toString()
      const tokenContract = new this.web3.eth.Contract(tokenAbi, token.address)

      const gasPrice = await getGasPriceForNetwork(this.offer.blockchain)

      await tokenContract.methods
        .approve(contractAddress, amount)
        .send({ from: this.userdata.wallet, gasPrice })
        .then(() => {
          if (this.onchain) {
            this.replenishOfferBudget(contract, amount, gasPrice)
          } else {
            this.createOfferOnchain(contract, token, amount, gasPrice)
          }
        })
        .catch(err => {
          console.log(err)

          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'error',
              message: `There was an error when performing a transaction. [Approve Function]`,
            },
            { root: true },
          )

          this.loading = false
        })
    },

    async createOfferOnchain(contract, token, amount, gasPrice) {
      const vesting = 60 * 60 * 24 * (this.offer.vesting || 0)

      await contract.methods
        .createOffer(this.offer._id, token.address, amount, vesting)
        .send({
          from: this.userdata.wallet,
          gasPrice,
        })
        .then(() => {
          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'success',
              message: `The offer has been successfully created in the blockchain network!`,
            },
            { root: true },
          )
        })
        .catch(err => {
          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'error',
              message: `There was an error when performing a transaction. [CreateOffer Function]`,
            },
            { root: true },
          )
        })
        .finally(() => {
          this.getOfferData()
        })
    },

    async replenishOfferBudget(contract, amount, gasPrice) {
      await contract.methods
        .addBudget(this.offer._id, amount)
        .send({
          from: this.userdata.wallet,
          gasPrice,
        })
        .then(() => {
          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'success',
              message: `The offer budget has been successfully increased!`,
            },
            { root: true },
          )
        })
        .catch(err => {
          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'error',
              message: `There was an error when performing a transaction. [CreateOffer Function]`,
            },
            { root: true },
          )
        })
        .finally(() => {
          this.getOfferData()
        })
    },
  },
}
</script>
