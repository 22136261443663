export const getCurlInstallation = (secret, wallet, payout, id) => 
`curl -X POST https://api.promote2earn.com/commission \\
  -h "X-Secret-Key: ${secret}" \\
  -d wallet=${wallet} \\
  -d amount=${payout} \\
  -d offer=${id}
`;

export const getJavascriptInstallation = (secret, wallet, payout, id) => 
`const axios = require('axios');

axios({
  method: 'POST',
  url: 'https://api.promote2earn.com/commission',
  headers: {
    X-Secret-Key: '${secret}'
  },
  data: {
    wallet: '${wallet}',
    amount: ${payout},
    offer: '${id}'
  }
});
`;
