<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ asset._id ? 'Edit Asset' : 'Create New Asset' }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close-modal')">
        <v-icon>{{icons.mdiClose}}</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-stepper
        non-linear
        elevation="0"
        class="pb-0"
        v-model="stepper"
        vertical
      >
        <v-stepper-step
          editable
          step="1"
        >
          Type
          <small>What you want to sell</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <category-step
            :asset.sync="asset"
            :canEditWeb3Fields="canEditWeb3Fields"
          ></category-step>
        </v-stepper-content>

        <v-stepper-step
          editable
          step="2"
        >
          Information
          <small>Smart contract</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <contract-step
            :asset.sync="asset"
            :canEditWeb3Fields="canEditWeb3Fields"
          ></contract-step>
        </v-stepper-content>

        <v-stepper-step
          editable
          step="3"
        >
          Public Information
          <small>Public information for the sale page</small>
        </v-stepper-step>

        <v-stepper-content step="3">
          <sale-step
            :asset.sync="asset"
          ></sale-step>
        </v-stepper-content>

        <v-stepper-step step="4" editable>
          Affiliate
          <small>Affiliate offe</small>
        </v-stepper-step>
      
        <v-stepper-content step="4">
          <integration-step
            :asset.sync="asset"
            :offers-list="offersList"
          ></integration-step>
        </v-stepper-content>
      </v-stepper>

      <small>*indicates required field</small>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error darken-1" text outlined v-if="stepper > 1" @click="stepper--">Back</v-btn>
      <v-btn color="primary darken-1" v-if="stepper < 4" @click="stepper++">Continue</v-btn>
      <v-btn
        v-if="stepper == 4"
        color="primary darken-1"
        :disabled="!canSaveAsset"
        @click="saveAsset()"
        :loading="saving"
      > Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiClose } from '@mdi/js'

import CategoryStep from '@/components/modals/variants/asset-builder/category-step/CategoryStep'
import ContractStep from '@/components/modals/variants/asset-builder/contract-step/ContractStep'
import SaleStep from '@/components/modals/variants/asset-builder/sale-step/SaleStep'
import IntegrationStep from '@/components/modals/variants/asset-builder/integration-step/IntegrationStep'

export default {
  components: {
    CategoryStep,
    IntegrationStep,
    SaleStep,
    ContractStep,
  },
  props: {
    assetData: {
      type: Object,
    },
    offers: {
      type: Array,
      required: true,
    },
    adminView: {
      type: Boolean,
    },
  },
  data: () => ({
    icons: { mdiClose },
    stepper: 1,
    menu: false,
    saving: false,
    userOffers: [],
    asset: {
      category: 'nft',
      type: 'custom',
      status: 'draft',
      key: null,
      name: null,
      description: null,
      preview: null,
      price: null,
      website: null,
      twitter: null,
      discord: null,
      blockchain: 'ETH',
      contract: {
        address: null,
        baseTokenURI: null,
        nftLimit: 1,
        quantity: 0,
        name: null,
        symbol: null,
      },
      payment: {
        price: 0,
        token: 'USDT',
      },
      integration: {
        affiliate: {
          offer: null,
        },
      },
    },
  }),
  created() {
    if (this.assetData) {
      this.asset = {
        ...this.asset,
        ...this.assetData,
        payment: {
          ...this.asset.payment,
          ...this.assetData.payment,
        },
      }
    }

    if (this.adminView) {
      this.getOffersByUser()
    }
  },
  computed: {
    canEditWeb3Fields() {
      return ['draft', 'review-failed'].includes(this.asset.status)
    },
    canSaveAsset() {
      if (['under-review'].includes(this.asset.status)) return false

      const { name, category, blockchain, type } = this.asset

      if (!name || !category || !type || !blockchain) {
        return false
      }

      const validators = {
        nft: {
          custom: () => !!this.asset.contract.address,
          internal: () => {
            const { baseTokenURI, nftLimit, name, symbol } = this.asset.contract

            return baseTokenURI && nftLimit && name && symbol
          },
        },
        whitelist: {
          custom: () => false,
          internal: () => {
            const { price, token } = this.asset.payment
            const { quantity } = this.asset.contract

            return price && token && quantity
          },
        },
      }

      const validator = validators[category] && validators[category][type]

      return validator ? validator() : true
    },
    offersList() {
      return this.adminView ? this.userOffers : this.offers
    },
  },
  methods: {
    saveAsset() {
      if (this.saving) return

      this.saving = true

      const url = this.asset._id ? `/asset/${this.asset._id}` : `/asset`
      const method = this.asset._id ? `put` : 'post'

      this.$http[method](url, this.asset)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Asset successfully saved!`,
              },
              { root: true },
            )
          }

          if (data.asset) {
            this.$set(this.asset, '_id', data.asset._id)
          }

          this.$emit('update')
          this.$emit('close-modal')
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    getOffersByUser() {
      this.$http
        .get('offer/user/all', {
          params: {
            owner: this.asset.owner._id,
          },
        })
        .then(({ data }) => {
          this.userOffers = data.offers
        })
        .catch(() => {})
    },
  },
}
</script>
