<template>
  <div class="connect-wrapper">
    <v-btn
      v-if="!isAuthenticated"
      color="primary"
      class="white--text"
      :disabled="connecting"
      :loading="connecting"
      @click="connect"
      text
      outlined
      small
    >
      <svg-changer class="mr-2" height="20px" width="20px">
        <metamask-icon></metamask-icon>
      </svg-changer>
     
      Connect
    </v-btn>
    <v-menu
      v-else
      :close-on-content-click="false"
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          dot
        >
          <v-avatar
            size="40px"
            v-bind="attrs"
            color="primary"
            class="v-avatar-light-bg primary--text"
            v-on="on"
          >
            <img
              :src="projectLogo || require(`@/assets/images/avatars/3.png`)"
              alt="Project Logo"
            >
            <!-- <v-icon size="25">{{icons.mdiAccountOutline}}</v-icon> -->
          </v-avatar>
        </v-badge>
      </template>
      <v-list expand>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar
              size="40px"
              color="primary"
              class="v-avatar-light-bg primary--text"
            >
              <v-icon size="25">{{icons.mdiAccountOutline}}</v-icon>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
            <span class="text--primary font-weight-semibold mb-n1 account-wallet">
              {{web3activeAccount}}
            </span>
            <small class="text--disabled text-capitalize">Wallet Address</small>
          </div>
        </div>

        <v-divider></v-divider>

        <div
          v-for="(item, index) in menuItems"
          :key="`${index}-${item.to}-${item.title}`"
        >
          <v-list-item :to="{ name: item.to }" v-if="item.to" active-class="bg-gradient-primary" exact>
            <v-list-item-icon class="me-2">
              <v-icon size="22">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-if="item.children">
              <template #activator>
                <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-if="child.to"
              v-for="(child, index) in item.children"
              :key="index"
              :to="{ name: child.to }"
            >
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ child.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>

        <v-divider></v-divider>

        <!-- Logout -->
        <v-list-item @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mdiAccountOutline, mdiLogoutVariant } from '@mdi/js'

import MetamaskIcon from '@/assets/images/svg/metamask.svg';

import navMenuItems from '@/navigation/vertical';

export default {
  components: {
    MetamaskIcon
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiLogoutVariant,
      },
    }
  },
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    ...mapState({
      connecting: state => state.web3auth.connecting,
      web3activeAccount: state => state.web3auth.account,
    }),
    projectLogo() {
      return this.userdata?.project?.logo
    },
    menuItems() {
      return navMenuItems.filter((item) => {
        return !((item.authRequired && !this.userdata._id) || (item.isAdmin && !this.userdata.isAdmin));
      });
    }
  },
  methods: {
    async connect() {
      await this.$store.dispatch('web3auth/CONNECT', { auto: false, role: 'publisher' })
    },
    logout() {
      this.$store.commit('web3auth/PURGE_AUTH')
    },
  },
}
</script>

<style lang="scss" scoped>
.account-wallet {
  text-overflow: ellipsis;
  max-width: 126px;
  overflow: hidden;
}
</style>
