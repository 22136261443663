import AssetBuilder from '@/components/modals/variants/asset-builder/AssetBuilder';
import AssetReview from '@/components/modals/variants/asset-review/AssetReview';
import AssetTransfer from '@/components/modals/variants/asset-transfer/AssetTransfer';

import OfferBudget from '@/components/modals/variants/offer-budget/OfferBudget';
import OfferBuilder from '@/components/modals/variants/offer-builder/OfferBuilder';
import OfferInstallation from '@/components/modals/variants/offer-installation/OfferInstallation';
import OfferTransfer from '@/components/modals/variants/offer-transfer/OfferTransfer';

import ProjectBuilder from '@/components/modals/variants/project-builder/ProjectBuilder';

export default {
  AssetBuilder,
  AssetTransfer,
  AssetReview,
  OfferBuilder,
  OfferBudget,
  OfferInstallation,
  OfferTransfer,
  ProjectBuilder
};
