import { mdiChemicalWeapon, mdiFormatListText, mdiInformation, mdiSecurity, mdiWalletOutline } from '@mdi/js';

export default [
  {
    title: 'Marketplace',
    icon: mdiWalletOutline,
    to: 'home',
  },
  {
    title: 'Sales',
    icon: mdiFormatListText,
    to: 'publisher-sales',
    authRequired: true,
  },
  {
    title: 'Offers',
    icon: mdiFormatListText,
    to: 'publisher-offers',
    authRequired: true,
  },
  {
    title: 'Advertise',
    icon: mdiChemicalWeapon,
    authRequired: true,
    children: [
      {
        title: 'Offers',
        to: 'advertiser-offers',
      },
      {
        title: 'Sales & Commissions',
        to: 'advertiser-sales',
      },
      {
        title: 'Publishers',
        to: 'advertiser-publishers',
      },
      {
        title: 'Company Settings',
        to: 'advertiser-company-settings',
      },
    ]
  },
  {
    title: 'Admin',
    icon: mdiChemicalWeapon,
    authRequired: true,
    isAdmin: true,
    children: [
      {
        title: 'Users',
        to: 'admin-system-users',
      },
      {
        title: 'Projects',
        to: 'admin-system-projects',
      },
      {
        title: 'Offers',
        to: 'admin-system-offers',
      }
    ]
  },
  {
    title: 'Docs',
    icon: mdiInformation,
    href: 'https://docs.promote2earn.com'
  }
]
