<template>
  <a href="https://polygonscan.com/address/0xf293cb4efd41523e7a922bb47c8fa68862ea2e19" target="_blank">
    <v-chip label color="primary" outlined>
      <v-icon class="mr-1">{{icons.mdiShieldCheck}}</v-icon>
      Check Contract
    </v-chip>
  </a>
</template>

<script>
import { mdiShieldCheck } from '@mdi/js'

export default {
  data: () => ({
    icons: { mdiShieldCheck }
  })
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;

  .v-chip {
    cursor: pointer !important;
  }
}
</style>
