<template>
  <v-card>
    <v-card-title>
      <span>Transfer [Asset - {{assetData.name}}]</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close-modal')">
        <v-icon>{{icons.mdiClose}}</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Wallet*"
            required
            outlined
            hint="Enter the wallet address to which you want to transfer control of this asset."
            persistent-hint
            v-model="wallet"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error darken-1" text outlined @click="$emit('close-modal')" :disabled="loading">Close</v-btn>
      <v-btn
        color="primary darken-1"
        outlined text
        :disabled="!canTransfer"
        @click="transfer()"
        :loading="loading"
      > Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
  props: {
    assetData: Object,
  },
  data: () => ({
    icons: { mdiClose },
    loading: false,
    wallet: null,
  }),
  computed: {
    ...mapGetters('web3auth', ['userdata']),
    canTransfer() {
      return this.wallet && this.userdata.isAdmin
    },
  },
  methods: {
    transfer() {
      if (this.loading) return

      this.loading = true

      this.$http
        .post('admin/assets/transfer', {
          assetId: this.assetData._id,
          wallet: this.wallet,
        })
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `The ownership of the asset has been successfully transferred!`,
              },
              { root: true },
            )
          }

          this.$emit('close-modal')
          this.$emit('update')
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
