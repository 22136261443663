<template>
  <div class="wrapper">
    <label
      v-for="(item, index) in items"
      :key="index"
      :for="`radio-card-${_uid}-${index}`"
      class="radio-card"
      :class="{ 'radio-card--text': text }"
    >
      <input
        type="radio"
        @input="$emit('input', $event.target.value)"
        @change="$emit('change', $event)"
        :value="item.value"
        :disabled="disabled || item.disabled"
        :checked="value === item.value"
        :name="`radio-card-${_uid}`"
        :id="`radio-card-${_uid}-${index}`"
      />

      <div class="card-content-wrapper" :style="{ width, height }">
        <span class="check-icon"></span>

        <div class="card-content">
          <slot :item="item" :index="index">
            <v-icon size="100" color="primary" class="mx-auto d-block" v-if="!text">{{ icons.mdiChartBubble }}</v-icon>

            <h4 class="mb-0">{{item.text}}</h4>
          </slot>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { mdiChartBubble } from '@mdi/js'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    text: {
      type: Boolean,
    },
    value: String,
    width: String,
    height: String,
  },
  data: () => ({
    icons: { mdiChartBubble },
  }),
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  display: block;
  vertical-align: middle;
}

.wrapper {
  display: grid;
  grid-gap: 30px;
  place-items: center;
  place-content: center;
  grid-auto-flow: column;
  grid-template-rows: auto;
}

label.radio-card {
  cursor: pointer;

  &.radio-card--text {
    .card-content-wrapper {
      display: flex;
      align-items: center;
      height: auto;
      width: 150px;

      .check-icon {
        margin-right: 15px;
      }
    }
  }

  .card-content-wrapper {
    background: #f3f3f3;
    border-radius: 5px;
    width: 200px;
    height: auto;
    padding: 15px;
    display: grid;
    box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
    transition: 200ms linear;
  }

  .check-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: solid 2px #e3e3e3;
    border-radius: 50%;
    transition: 200ms linear;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: center center;
      transform: scale(1.6);
      transition: 200ms linear;
      opacity: 0;
    }
  }

  input[type='radio'] {
    appearance: none;
    &:checked {
      + .card-content-wrapper {
        box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px var(--v-primary-base);

        .check-icon {
          background: var(--v-primary-base);
          border-color: var(--v-primary-base);
          transform: scale(1.2);

          &:before {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }

    &[disabled]:checked {
      + .card-content-wrapper {
        box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px var(--v-secondary-base);

        .check-icon {
          background: var(--v-secondary-base);
          border-color: var(--v-secondary-base);
        }
      }
    }

    &:focus {
      + .card-content-wrapper {
        .check-icon {
          box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
          border-color: var(--v-primary-base);
        }
      }
    }
  }

  .card-content {
    img {
      margin-bottom: 10px;
    }
    h4 {
      font-size: 16px;
      letter-spacing: -0.24px;
      text-align: center;
      color: #1f2949;
      margin-bottom: 10px;
    }

    h5 {
      font-size: 14px;
      line-height: 1.4;
      text-align: center;
      color: #686d73;
    }
  }
}
</style>