import store from '@/store';
import axios from 'axios';
import { utils } from 'ethers';
import Web3 from 'web3';

export const getContract = async (network, token, contractName) => {
  const { networks, abi } = store.state.app.web3;

  const result = { web3: null, contract: null, error: null };

  const networkConfig = networks[network];

  if (!networkConfig || !networkConfig.provider) {
    result.error = `Configuration not found for ${network} network`;
    return result;
  }

  const tokenConfig = networkConfig.coins && networkConfig.coins[token];

  if (!tokenConfig || !tokenConfig.address) {
    result.error = `Configuration not found for ${token} token`;
    return result;
  }

  const contractAbi = abi[contractName];
  const contractAddress = networkConfig.contracts && networkConfig.contracts[contractName]

  if (!contractAbi || !contractAddress) {
    result.error = `Invalid contract [${contractName}] configuration (abi or address)`;
    return result;
  }

  result.web3 = new Web3(networkConfig.provider);
  result.contract = new result.web3.eth.Contract(contractAbi, contractAddress);

  return result
};

export const getEthersFromWei = (network, coinName, amount) => {
  const { networks } = store.state.app.web3;
  const decimals = networks[network]?.coins?.[coinName]?.decimals;

  return utils.formatUnits(amount, decimals || 'ether');
};

export const getWeiFromEthers = (network, coinName, amount) => {
  const { networks } = store.state.app.web3;
  const decimals = networks[network]?.coins?.[coinName]?.decimals;

  return utils.parseUnits(amount, decimals || 'ether');
};

export const getGasPriceForNetwork = async (network) => {
  const gasStations = {
    POLYGON: 'https://gasstation-mainnet.matic.network'
  };

  if (!gasStations[network]) return;

  const result = await axios.get(gasStations[network])
    .then(res => {
      return res.data && res.data.standard;
    }).catch(() => null);

  if (!result) return;

  return Web3.utils.toWei(Number(result).toString(), 'gwei');
};
