<template>
  <main>
    <fullpage-loader v-show="showFullPageLoader"></fullpage-loader>
    <transaction-loader></transaction-loader>
  
    <component
      v-show="!showFullPageLoader"
      :is="resolveLayoutVariant"
      :class="`skin-variant--${appSkinVariant}`"
    >
      <transition
        :name="appRouteTransition"
        mode="out-in"
        appear
      >
        <router-view></router-view>
      </transition>

      <notification></notification>
      <modal-window></modal-window>
    </component>
  </main>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useLayoutConfig from '@/composable/useLayoutConfig'
import { useRouter } from '@/utils'
import { useLayout } from '@/layouts/composable/useLayout'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh
import useDynamicVh from '@/composable/useDynamicVh'

// Components
import Notification from '@/components/ui/notification/Notification.vue'
import FullpageLoader from '@/components/ui/loaders/FullpageLoader.vue'
import TransactionLoader from '@/components/ui/loaders/TransactionLoader.vue'

import ModalWindow from '@/components/modals/Modal.vue'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,

    LayoutBlank,
    Notification,
    FullpageLoader,
    TransactionLoader,

    ModalWindow,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useLayoutConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      const layout = route.value.meta.layout

      if (['blank'].includes(layout)) return `layout-${layout}`
      if (layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
  computed: {
    ...mapState({
      showFullPageLoader: state => state.app.showFullPageLoader,
    }),
  },
}
</script>
