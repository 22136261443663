import Vue from 'vue';
import VueRouter from 'vue-router';

import { validateRouteWithAuthData } from './guard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/marketplace/Marketplace.vue'),
    meta: {
      layout: 'content'
    },
  },
  {
    path: '/campaign/:projectId',
    name: 'project-page',
    component: () => import('@/views/ProjectPage.vue'),
    meta: {
      layout: 'content'
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/Onboarding.vue'),
    meta: {
      layout: 'content',
      authRequired: true
    },
  },
  {
    path: '/advertiser',
    name: 'advertiser',
    redirect: '/advertiser/offers'
  },
  {
    path: '/publisher',
    name: 'publisher',
    redirect: '/publisher/sales'
  },
  {
    path: '/advertiser/offers',
    name: 'advertiser-offers',
    component: () => import('@/views/advertiser/offers/AdvertiserOffers.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
      onbordingRequired: true
    },
  },
  {
    path: '/advertiser/sales',
    name: 'advertiser-sales',
    component: () => import('@/views/advertiser/sales/AdvertiserSales.vue'),
    meta: {
      layout: 'content',
      authRequired: true
    },
  },
  {
    path: '/advertiser/publishers',
    name: 'advertiser-publishers',
    component: () => import('@/views/advertiser/publishers/AdvertiserPublishers.vue'),
    meta: {
      layout: 'content',
      authRequired: true
    },
  },
  {
    path: '/advertiser/company-settings',
    name: 'advertiser-company-settings',
    component: () => import('@/views/advertiser/company-settings/AdvertiserCompanySettings.vue'),
    meta: {
      layout: 'content',
      authRequired: true
    },
  },
  {
    path: '/publisher/sales',
    name: 'publisher-sales',
    component: () => import('@/views/publisher/sales/PublisherSales.vue'),
    meta: {
      layout: 'content',
      authRequired: true
    },
  },
  {
    path: '/publisher/offers',
    name: 'publisher-offers',
    component: () => import('@/views/publisher/offers/PublisherOffers.vue'),
    meta: {
      layout: 'content',
      authRequired: true
    },
  },
  {
    path: '/admin/system/users',
    name: 'admin-system-users',
    component: () => import('@/views/admin/users/AdminUsers.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
      isAdmin: true
    },
  },
  {
    path: '/admin/system/projects',
    name: 'admin-system-projects',
    component: () => import('@/views/admin/projects/AdminProjects.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
      isAdmin: true
    },
  },
  {
    path: '/admin/system/offers',
    name: 'admin-system-offers',
    component: () => import('@/views/admin/offers/AdminOffers.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
      isAdmin: true,
      onbordingRequired: true
    },
  },
  {
    path: '*',
    redirect: '/'
  }
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

router.beforeEach(validateRouteWithAuthData);

export default router;
