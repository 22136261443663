<template>
  <component
    :is="resolveComponentVariant"
    :asset.sync="asset"
    :canEditWeb3Fields="canEditWeb3Fields"
  ></component>
</template>

<script>
import NftContractStep from '@/components/modals/variants/asset-builder/contract-step/nft-contract-step/NftContractStep'
import WhitelistContractStep from '@/components/modals/variants/asset-builder/contract-step/whitelist-contract-step/WhitelistContractStep'

export default {
  components: {
    NftContractStep,
    WhitelistContractStep,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    canEditWeb3Fields: Boolean,
  },
  computed: {
    resolveComponentVariant() {
      return `${this.asset.category}-contract-step`
    },
  },
}
</script>
