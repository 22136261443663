<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    elevation="0"
    no-click-animation
    persistent
    content-class="transaction-loader"
    :value="show"
  >
    <template v-slot:default>
      <div class="content">
        <h2 class="white--text mb-5" v-if="message">{{message}}</h2>
        <v-progress-circular
          :size="80"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      show: state => state.app.transactionLoader.show,
      message: state => state.app.transactionLoader.message
    })
  }
}
</script>

<style lang="scss">
.transaction-loader {
  box-shadow: none !important;
  padding: 40px;
  text-align: center;
}
</style>