<template>
  <div class="v-alert-wrapper">
      <transition-group name="fade">
        <v-alert
          v-for="notification of notificatios"
          :key="notification.id"
          :type="notification.type"
          :icon="getIconByType(notification.type)"
          elevation="2"
          colored-border
          dismissible
          border="left"
        >
          <span v-html="notification.message"></span>
        </v-alert>
      </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { mdiAlertCircle, mdiAlert, mdiCheckDecagram, mdiInformationOutline } from '@mdi/js';

export default {
  name: 'Notification',
  setup() {
    return {
      icons: {
        mdiAlertCircle,
        mdiCheckDecagram,
        mdiInformationOutline,
        mdiAlert
      }
    }
  },
  computed: {
    ...mapState({
      notificatios: state => state.notification.list
    })
  },
  methods: {
    getIconByType(type) {
      const iconsByType = {
        'error': this.icons.mdiAccountAlert,
        'success': this.icons.mdiCheckDecagram,
        'info': this.icons.mdiInformationOutline,
        'warning': this.icons.mdiAlert
      }
     
      return iconsByType[type] || this.icons.mdiAlert;
    }
  }
}
</script>

<style scoped>
.v-alert-wrapper {
  position: fixed;
  z-index: 999;
  max-width: 600px;
  width: 100%;
  right: 20px;
  bottom: 20px;
}
</style>
