<template>
  <v-row class="mx-0 my-0">
    <v-col cols="12" sm="4">
      <v-select
        :items="blockchainList"
        label="Blockchain*"
        required
        outlined
        v-model="asset.blockchain"
        :disabled="!canEditWeb3Fields"
        @change="asset.payment.token = 'USDT'"
        hint="Specify the blockchain"
        persistent-hint
        dense
        hide-details="auto"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
        label="Name*"
        :disabled="!canEditWeb3Fields"
        outlined
        v-model="asset.contract.name"
        hint="Specify the contract name"
        persistent-hint
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
        label="Ticker*"
        :disabled="!canEditWeb3Fields"
        outlined
        v-model="asset.contract.symbol"
        hint="Specify the contract ticker"
        persistent-hint
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
        label="Metadata URI*"
        :disabled="!canEditWeb3Fields"
        outlined
        v-model="asset.contract.baseTokenURI"
        hint="Specify a URL that will point to the metadata of your NFTs."
        persistent-hint
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
        label="Price*"
        outlined
        v-model="asset.payment.price"
        dense
        type="number"
        step=".1"
        hint="Selling price"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
        label="Quantity*"
        :disabled="!canEditWeb3Fields"
        outlined
        v-model="asset.contract.nftLimit"
        hint="Specify the maximum number of NFTs"
        type="number"
        persistent-hint
        dense
        hide-details="auto"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    canEditWeb3Fields: Boolean,
  },
  data: () => ({
    blockchainList: ['ETH', 'POLYGON', 'BSC', 'BSC_TESTNET'],
  }),
}
</script>
