<template>
  <v-row class="mx-0 my-0">
    <v-col cols="12" sm="6">
      <v-select
        :items="blockchainList"
        label="Blockchain*"
        required
        outlined
        v-model="asset.blockchain"
        :disabled="!canEditWeb3Fields"
        @change="asset.payment.token = 'USDT'"
        hint="Specify the blockchain"
        persistent-hint
        dense
        hide-details="auto"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        label="Price*"
        outlined
        v-model="asset.payment.price"
        dense
        type="number"
        step=".1"
        hint="Selling price"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6">
      <v-select
        :items="tokens"
        label="Token*"
        required
        outlined
        v-model="asset.payment.token"
        hint="Specify payment token"
        persistent-hint
        dense
        hide-details="auto"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        label="Quantity*"
        outlined
        v-model="asset.contract.quantity"
        dense
        type="number"
        hint="Specify asset quantity"
        persistent-hint
        hide-details="auto"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    canEditWeb3Fields: Boolean,
  },
  data: () => ({
    blockchainList: ['POLYGON', 'BSC_TESTNET'],
  }),
  computed: {
    ...mapState({
      tokenList: state => state.app.tokenList,
      web3config: state => state.app.web3,
    }),
    tokens() {
      const nativeToken = this.web3config.networks[this.asset.blockchain]?.nativeTokenName

      return [...this.tokenList, nativeToken].filter(it => !!it)
    },
  },
}
</script>
