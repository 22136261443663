import { getAxios } from '@/utils';

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    showFullPageLoader: true,
    transactionLoader: {
      show: false,
      message: null
    },
    projects: [],
    // NODE: ETH, BSC - will need to be added to the list when contracts are deployed and addresses are added to the configuration
    blockchainList: ['POLYGON', 'BSC_TESTNET'],
    tokenList: ['USDT', 'USDC'],
    web3: {
      networks: {},
      abi: {}
    },
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    HIDE_FULL_PAGE_LOADER(state) {
      state.showFullPageLoader = false;
    },
    SET_PROJECTS(state, projects) {
      state.projects = projects;
    },
    TOGGLE_TRANSACTION_LOADER(state, { show, message = null }) {
      state.transactionLoader.show = show;
      state.transactionLoader.message = message;
    },
    SET_WEB3_CONFIG(state, config) {
      state.web3.networks = config.networks || state.web3.networks;
      state.web3.abi = config.abi || state.web3.abi;
    }
  },
  actions: {
    GET_ALL_PROJECTS({ commit }, params) {
      return getAxios().get("project/all", params)
        .then(({ data }) => {
          commit('SET_PROJECTS', data.projects || []);

          return data
        })
        .catch((err) => {
          console.log(`[API ERROR]: ${err}`)
        });
    }
  },
}
