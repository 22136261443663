<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Review Asset [{{assetData.name}}] [{{assetData.blockchain}}]</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            label="Status*"
            required
            outlined
            v-model="status"
            :items="statuses"
            :disabled="disableReviewStatus"
            dense
            persistent-hint
            hide-details="auto"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Review Message"
            outlined
            v-model="message"
            :disabled="disableReviewStatus"
            dense
            persistent-hint
            hide-details="auto"
          ></v-text-field>
        </v-col>
  
        <v-col cols="12">
          <v-textarea
            label="ABI*"
            required
            outlined
            v-model="contract.abi"
            dense
            hide-details="auto"
          ></v-textarea>
          
          <span class="primary--text">Valid: {{validContractABI}}</span>
        </v-col>

        <v-col cols="12">
          <v-textarea
            label="Mint Inject Code*"
            required
            outlined
            v-model="contract.mintInjectCode"
            dense
            hide-details="auto"
          ></v-textarea>

          <span class="primary--text">Result: {{mintInjectCodeResult}}</span>
        </v-col>

        <v-col cols="12">
          <v-textarea
            label="Cost Inject Code*"
            outlined
            v-model="contract.costInjectCode"
            dense
            hide-details="auto"
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <v-textarea
            label="Total Supply Inject Code*"
            outlined
            v-model="contract.totalSupplyInjectCode"
            dense
            hide-details="auto"
          ></v-textarea>
        </v-col>


        <v-col cols="12">
          <v-textarea
            label="Max Supply Inject Code*"
            outlined
            v-model="contract.maxSupplyInjectCode"
            dense
            hide-details="auto"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error darken-1" text outlined @click="$emit('close-modal')" :disabled="saving">Close</v-btn>
      <v-btn
        color="primary darken-1"
        outlined text
        :disabled="!canSave"
        @click="saveAsset()"
        :loading="saving"
      > Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VueEditor } from 'vue2-editor'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    VueEditor,
    PerfectScrollbar,
  },
  props: {
    assetData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    menu: false,
    saving: false,
    statuses: ['draft', 'under-review', 'review-failed', 'live'],
    message: null,
    status: null,
    contract: {},
  }),
  created() {
    this.contract = { ...this.assetData.contract }
    this.status = this.assetData.status
    this.message = this.assetData.review && this.assetData.review.message
  },
  computed: {
    disableReviewStatus() {
      return ['draft', 'live'].includes(this.assetData.status)
    },
    canSave() {
      const { abi, mintInjectCode, totalSupplyInjectCode, maxSupplyInjectCode, costInjectCode } = this.contract

      return this.status && abi && mintInjectCode && totalSupplyInjectCode && maxSupplyInjectCode && costInjectCode
    },
    validContractABI() {
      if (!this.contract.abi) return false

      try {
        return Array.isArray(JSON.parse(this.contract.abi))
      } catch (err) {
        return false
      }
    },
    mintInjectCodeResult() {
      if (!this.contract.mintInjectCode) return '---'

      try {
        return JSON.stringify(eval(this.contract.mintInjectCode))
      } catch (err) {
        return err.message
      }
    },
  },
  methods: {
    saveAsset() {
      if (this.saving) return

      this.saving = true

      this.$http
        .put(`admin/assets/${this.assetData._id}/review-feedback`, {
          status: this.status,
          message: this.message,
          ...this.contract,
        })
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Asset successfully saved!`,
              },
              { root: true },
            )
          }

          this.$emit('update')
          this.$emit('close-modal')
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.saving = false
        })
    },
  },
}
</script>
