import Vue from 'vue'
import Vuex from 'vuex'

import app from './app.module'
import layout from './layout.module'
import modal from './modal.module'
import notification from './notification.module'
import offer from './offer.module'
import web3auth from './web3auth.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    layout,
    app,
    notification,
    web3auth,
    offer,
    modal
  },
})
