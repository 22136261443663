import '@/plugins/vue-composition-api';

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

import { i18n } from '@/plugins/i18n';

import './plugins/axios';

Vue.config.productionTip = false;

/* Global Components */
import SvgChanger from '@/components/core/svg-changer/SvgChanger.vue';
Vue.component('svg-changer', SvgChanger);

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
