<template>
  <div>
    <v-row class="mx-0 my-0" v-if="showRadioButtonCards">
      <v-col cols="12">
        <radio-button-cards
          :items="categories"
          :disabled="asset.status !== 'draft'"
          width="150px"
          height="150px"
          @change="onChangeAssetCategory()"
          v-model="asset.category"
        >
          <template v-slot:default="{ item }">
            <img class="mx-auto" width="40px" :src="item.image" :alt="item.text">

            <h4>{{item.text}}</h4>
          </template>
        </radio-button-cards>
      </v-col>

      <v-col cols="12" v-if="asset.category !== 'whitelist'">
        <radio-button-cards
          :items="types"
          :disabled="!canEditWeb3Fields"
          text
          v-model="asset.type"
        >
        </radio-button-cards>
      </v-col>
    </v-row>

    <v-row class="mx-0 my-0" v-else>
      <v-col cols="12" sm="6">
        <v-select
          label="Category*"
          :items="categories.filter(it => !it.disabled)"
          required
          outlined
          v-model="asset.category"
          :disabled="asset.status !== 'draft'"
          dense
          persistent-hint
          hide-details="auto"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" v-if="asset.category !== 'whitelist'">
        <v-select
          label="Type*"
          :items="types.filter(it => !it.disabled)"
          required
          outlined
          :disabled="!canEditWeb3Fields"
          v-model="asset.type"
          persistent-hint
          dense
          hide-details="auto"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RadioButtonCards from '@/components/ui/radio-button-cards/RadioButtonCards'

export default {
  components: {
    RadioButtonCards,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    canEditWeb3Fields: Boolean,
  },
  data: () => ({
    categories: [
      {
        value: 'nft',
        text: 'Non-Fungible Token (NFT)',
        image: '/images/svg/nft.svg',
      },
      {
        value: 'whitelist',
        text: 'Whitelist',
        image: '/images/svg/whitelist.svg',
      },
      {
        value: 'token',
        text: 'Token',
        disabled: true,
        image: '/images/svg/token.svg',
      },
    ],
    types: [
      { value: 'custom', text: 'Existing', image: '/images/svg/token.svg' },
      { value: 'internal', text: 'New', image: '/images/svg/token.svg' },
    ],
  }),
  computed: {
    showRadioButtonCards() {
      return this.$vuetify.breakpoint.width > 900
    },
    blockchainList() {
      if (this.asset.category === 'whitelist') {
        return ['POLYGON', 'BSC_TESTNET']
      }

      return ['ETH', 'POLYGON', 'BSC', 'BSC_TESTNET']
    },
  },
  methods: {
    onChangeAssetCategory() {
      if (this.asset.category !== 'whitelist') {
        this.asset.type = 'custom'
        return
      }

      this.asset.type = 'internal'

      if (!this.blockchainList.includes(this.asset.blockchain)) {
        this.asset.blockchain = this.blockchainList[0]
      }
    },
  },
}
</script>
