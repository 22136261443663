<template>
  <v-card>
    <v-card-title>
      <span>Installation [Offer - {{offerData.name}}]</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close-modal')">
        <v-icon>{{icons.mdiClose}}</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <p>
        Use this code to send a commission to Promote2Earn.
      </p>

      <div class="code-wrapper">
        <div class="actions-wrapper">
          <v-select
            dark
            class="mb-0 pb-0"
            label="Language"
            :items="languages"
            v-model="language"
            hide-details
            dense
            max-width="60"
            outlined
          ></v-select>
        </div>
        <vue-code-highlight :language="language" class="codehighlight" :key="language">
          <pre v-html="code"></pre>
        </vue-code-highlight>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary" outlined class="mr-2"
        @click="copyCodeToClipboard"
        href="https://promote2earn.docs.apiary.io/#reference/0/commission/increase-commission"
        target="_blank"
      >Test the code on ApiAry</v-btn>
      <v-btn color="primary" outlined class="mr-2" @click="copyCodeToClipboard">Copy Code</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { component as VueCodeHighlight } from 'vue-code-highlight'

import 'vue-code-highlight/themes/duotone-sea.css'
import 'vue-code-highlight/themes/window.css'

import { mdiClose } from '@mdi/js'
import { getJavascriptInstallation, getCurlInstallation } from '@/assets/installation'

export default {
  components: {
    VueCodeHighlight,
  },
  props: {
    offerData: Object,
  },
  data: () => ({
    icons: { mdiClose },
    languages: ['javascript', 'curl'],
    language: 'javascript',
  }),
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    code() {
      const codes = {
        curl: getCurlInstallation(this.userdata.project.secret, '0x000...', this.offerData.payout, this.offerData._id),
        javascript: getJavascriptInstallation(
          this.userdata.project.secret,
          '0x000...',
          this.offerData.payout,
          this.offerData._id,
        ),
      }

      return codes[this.language]
    },
  },
  methods: {
    copyCodeToClipboard() {
      navigator.clipboard.writeText(this.code)

      this.$store.dispatch(
        'notification/GENERATE_NOTIFICATION',
        {
          type: 'success',
          message: 'Secret key successfully copied to clipboard!',
        },
        { root: true },
      )
    },
  },
}
</script>

<style scoped lang="scss">
.code-wrapper {
  position: relative;

  .actions-wrapper {
    position: absolute;
    right: 10px;
    top: 16px;
    display: flex;
    align-items: center;

    .v-select {
      max-width: 139px;
    }
  }
}

::v-deep .language-javascript::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep .language-javascript::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
::v-deep .language-javascript::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 10px;
}
</style>
