const defaultModalAttrs = {
  persistent: true,
  'max-width': 1200
};

export default {
  namespaced: true,
  state: {
    show: false,
    type: null,
    modalAttrs: defaultModalAttrs,
    componentAttrs: {},
    params: {}
  },
  mutations: {
    SET_OPTIONS(state, { show = false, type, modalAttrs = {}, componentAttrs = {}}) {
      state.show = show;

      if (show) {
        state.type = type;
        
        state.componentAttrs = componentAttrs;
        state.modalAttrs = { ...defaultModalAttrs, ...modalAttrs };
      }
    }
  }
}
