<template>
  <component
    :is="resolveComponentVariant"
    :asset.sync="asset"
    :canEditWeb3Fields="canEditWeb3Fields"
  ></component>
</template>

<script>
import InternalNftContractStep from '@/components/modals/variants/asset-builder/contract-step/nft-contract-step/InternalNftContractStep'
import CustomNftContractStep from '@/components/modals/variants/asset-builder/contract-step/nft-contract-step/CustomNftContractStep'

export default {
  components: {
    InternalNftContractStep,
    CustomNftContractStep,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    canEditWeb3Fields: Boolean,
  },
  computed: {
    resolveComponentVariant() {
      return `${this.asset.type}-nft-contract-step`
    },
  },
}
</script>
