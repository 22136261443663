import { getAxios } from '@/utils';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async SAVE_OFFER_CACHE({}, { offerId, web3data }) {
      return await getAxios().put(`offer/${offerId}/cache`, { web3data })
        .then(({ data }) => {
          return data
        })
        .catch((err) => {
          console.log(`[API ERROR]: ${err}`)
        });
    },
  },
}
