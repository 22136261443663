<template>
  <v-dialog
    v-bind="modalAttrs"
    v-model="show"
  >
    <component
      v-if="show"
      :is="type"
      v-bind="componentAttrs"
      @update="onEvent('update', $event)"
      @close-modal="close"
    ></component>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import { eventBus } from '@/utils/eventBus'
import components from '@/components/modals/variants'

export default {
  components,
  computed: {
    ...mapState({
      type: state => state.modal.type,
      modalAttrs: state => state.modal.modalAttrs,
      componentAttrs: state => state.modal.componentAttrs,
    }),
    show: {
      get() {
        return this.$store.state.modal.show
      },
      set() {
        return this.close()
      },
    },
  },
  methods: {
    close() {
      this.$store.commit('modal/SET_OPTIONS', { show: false })
    },
    onEvent(event, data) {
      eventBus.$emit(`${this.type}-${event}`, data)
    },
  },
}
</script>
