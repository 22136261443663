<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ offer._id ? 'Edit Offer' : 'Create New Offer' }}</span>
    </v-card-title>

    <v-card-text>
      <v-alert
        v-if="offer.status !== 'draft'"
        border="left"
        type="info"
        outlined
        class="mb-6"
      >
        Your offer is already published on the blockchain. Some fields can't be edited!
      </v-alert>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Name*"
            required
            outlined
            placeholder="Name of your offer. i.e. 'My NFT mint'"

            v-model="offer.name"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Link*"
            required
            placeholder="Enter your website / launchpad URL"
            outlined
            v-model="offer.link"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            label="Type*"
            required
            outlined
            :items="types"
            v-model="offer.type"
            dense
            hide-details="auto"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Image"
            required
            placeholder="Offer image"
            outlined
            v-model="offer.image"
            clearable
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
            label="Description*"
            required
            outlined
            v-model="offer.description"
            placeholder="Enter the offer description and condition to get commission. i.e. Send users to mint NFT and get 50$ commission per mint"
            dense
            no-resize
            height="60"
            hide-details="auto"
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="4">
          <v-switch
            label="Advanced Options"
            outlined
            v-model="advancedOptions"
            dense
            no-resize
            height="60"
            class="mt-0 pt-0"
            hide-details="auto"
          ></v-switch>
        </v-col>

        <v-col cols="12" sm="4" v-if="advancedOptions">
          <v-switch
            label="Show On Marketplace"
            outlined
            v-model="offer.showOnMarkeptlace"
            dense
            no-resize
            height="60"
            class="mt-0 pt-0"
            hide-details="auto"
          ></v-switch>
        </v-col>
      </v-row>

      <div class="d-flex align-center mb-5">
        <v-subheader>Payout</v-subheader>
        <v-divider></v-divider>
      </div>

      <v-row>
        <v-col cols="12" sm="4" v-if="advancedOptions">
          <v-select
            :items="blockchainList"
            label="Blockchain*"
            required
            outlined
            v-model="offer.blockchain"
            :disabled="offer.status !== 'draft'"
            dense
            hide-details="auto"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4" v-if="advancedOptions">
          <v-select
            :items="tokenList"
            label="Token*"
            required
            outlined
            v-model="offer.token"
            :disabled="offer.status !== 'draft'"
            dense
            hide-details="auto"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Payout Amount*"
            required
            outlined
            type="number"
            v-model="offer.payout"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <!--
        <v-col cols="12" sm="4">
          <v-text-field
            label="Vesting"
            required
            outlined
            type="number"
            v-model="offer.vesting"
            :disabled="offer.status !== 'draft'"
            persistent-hint
            hint="Number of vesting days"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      -->

        <!-- <v-col cols="12" sm="4" v-if="advancedOptions">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="offer.endDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="offer.endDate"
                outlined
                dense
                label="End Date (optional)"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="offer.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(offer.endDate)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col> -->
      </v-row>

      <small>*indicates required field</small>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error darken-1" text outlined @click="$emit('close-modal')" :disabled="saving">Close</v-btn>
      <v-btn
        color="primary darken-1"
        outlined text
        :disabled="!canSaveOffer"
        @click="saveOffer()"
        :loading="saving"
      > Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    offerData: {
      type: Object,
    },
  },
  data: () => ({
    menu: false,
    saving: false,
    types: ['NFT', 'Token', 'Lead', 'Purchase', 'Social'],
    advancedOptions: false,
    offer: {
      name: null,
      link: null,
      type: null,
      description: null,
      image: null,
      payout: 0,
      vesting: 0,
      status: 'draft',
      blockchain: 'POLYGON',
      token: 'USDT',
      endDate: null,
      showOnMarkeptlace: true,
    },
  }),
  created() {
    if (this.offerData) {
      this.offer = {
        ...this.offer,
        ...this.offerData,
      }
    }
  },
  computed: {
    ...mapState({
      blockchainList: state => state.app.blockchainList,
      tokenList: state => state.app.tokenList,
    }),
    canSaveOffer() {
      const { name, link, type, description, blockchain, token, payout } = this.offer

      return name && link && type && description && blockchain && token && payout
    },
  },
  methods: {
    saveOffer() {
      if (this.saving) return

      this.saving = true

      const url = this.offer._id ? `/offer/${this.offer._id}` : `/offer`
      const method = this.offer._id ? `put` : 'post'

      this.$http[method](url, this.offer)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Offer successfully saved!`,
              },
              { root: true },
            )
          }

          if (data.offer) {
            this.$set(this.offer, '_id', data.offer._id)
          }

          this.$emit('update')
          this.$emit('close-modal')
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.saving = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input--switch {
  ::v-deep .v-input__slot {
    height: 40px !important;
  }
}
</style>
