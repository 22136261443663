<script>
export default {
  name: 'vue-svg-changer',
  render(createElement) {
    const nodes = this.$slots.default

    if (!nodes || nodes.length !== 1 || nodes[0].tag !== 'svg') {
      throw new Error('Svg Changer: Incorrect default slot data. There must be only one svg component.')
    }

    const svg = { ...nodes[0] }

    return createElement(
      'svg',
      {
        style: {
          ...svg.data.style,
        },
        attrs: {
          ...svg.data.attrs,
          width: this.width,
          height: this.height,
          fill: this.getColor('fill', svg.data.attrs.fill),
          stroke: this.getColor('stroke', svg.data.attrs.stroke),
        },
        on: this.listeners,
      },
      this.resolveChildComponents(svg.children, createElement),
    )
  },
  props: {
    fill: {
      type: [String, Object],
    },
    stroke: {
      type: [String, Object],
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    hoverFillColor: {
      type: [String, Object],
    },
    hoverStrokeColor: {
      type: [String, Object],
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    listeners() {
      const result = {}

      if (this.hoverFillColor || this.hoverStrokeColor) {
        result.mouseover = () => this.mouseHoverEvent('over')
        result.mouseleave = () => this.mouseHoverEvent('leave')
      }

      if (this.$listeners.click) {
        result.click = event => this.$emit('click', event)
      }

      return result
    },
  },
  methods: {
    // TODO: Replace to utils
    isObject(item) {
      return typeof item === 'object' && !Array.isArray(item) && item !== null
    },

    getHoverPropByType(type) {
      const capitalized = type.charAt(0).toUpperCase() + type.slice(1)
      return 'hover' + capitalized + 'Color'
    },

    getColor(type, prev) {
      if (!['fill', 'stroke'].includes(type)) {
        return prev
      }

      const hasPrevValue = prev && prev !== 'none'

      if (this.hover) type = this.getHoverPropByType(type)

      const newValue = this.isObject(this[type]) ? this[type][prev] : this[type]

      return hasPrevValue && newValue ? newValue : prev
    },

    resolveChildComponents(childs, createElement) {
      if (!childs) return

      return childs.map(item => {
        const attributes = item.data?.attrs

        const fill = this.getColor('fill', attributes?.fill)
        const stroke = this.getColor('stroke', attributes?.stroke)

        const cloned = createElement(
          item.tag,
          {
            ...item.data,
            attrs: {
              ...attributes,
              fill,
              stroke,
            },
          },
          this.resolveChildComponents(item.children, createElement),
        )

        cloned.text = item.text
        cloned.isComment = item.isComment
        cloned.componentOptions = item.componentOptions
        cloned.elm = item.elm
        cloned.context = item.context
        cloned.ns = item.ns
        cloned.isStatic = item.isStatic
        cloned.key = item.key

        return cloned
      })
    },

    mouseHoverEvent(event) {
      this.hover = event === 'over'
    },
  },
}
</script>
